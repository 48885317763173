.top_menu_text{
  color: #4A4A4A;
  font-family: 'Merriweather', serif;
  font-style: italic;
  font-weight: 800;
  margin-left: 24px; 
  letter-spacing: 4px;
  font-size: 20px;
  margin-bottom: 4px;
}

.top_menu_link_underline:hover{  
  text-align: center;  
  padding: 0;
  transition: 'all 0.2s ease-in-out';
  position: relative;
}

.top_menu_link_underline::before{
}

.top_menu_link_underline::after{
  content: "";
  position: absolute;
  bottom: -10px;
  width: 0px;
  height: 5px;
  margin: 5px 0 0;
  transition:'all 0.2s ease-in-out';
  transition-duration: 0.75s;
  opacity: 0;
  background-color: #000;
}
